<template>
  <div class="main-wrapper">
    <Navbar />
    <BreadCrumb
      title="Career"
      desc="What’s the best thing about working at CloudTech? Easy. The people."
    />
    <Jobs />
    <WhyJoin />
    <FooterWithBg />
  </div>
</template>

<script>
import Jobs from "../../components/career/Jobs.vue";
import WhyJoin from "../../components/career/WhyJoin.vue";
import BreadCrumb from "../../components/common/BreadCrumb.vue";
import Navbar from "../../components/nav/Navbar.vue";
import FooterWithBg from "../../components/footer/FooterWithBg.vue";
export default {
  components: { Navbar, BreadCrumb, Jobs, WhyJoin, FooterWithBg },
};
</script>
BreadCrumb
