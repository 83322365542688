import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueAwesomeSwiper from "vue-awesome-swiper";
import CoolLightBox from "vue-cool-lightbox";

//Bootstrap CSS,JS
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

//CSS
import "./assets/css/style.css";
import "./assets/css/fontawesome-all.min.css";
import "./assets/css/swiper-bundle.min.css";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

Vue.use(VueAwesomeSwiper);
Vue.use(CoolLightBox);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
