<template>
  <section class="career-promo ptb-120 bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-12">
          <div class="section-heading text-center">
            <h4 class="h5 text-primary">Why Join Us</h4>
            <h2>Great Working Environment</h2>
            <p>
              “There are no secrets to success. It is the result of preparation, hard work, and learning from failure.” “It's about getting the best people, retaining them, nurturing a creative environment & helping to find a way to innovate.” "Success seems to be connected with action.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-lg-6">
          <div class="single-feature d-flex mt-0 p-5 bg-white rounded-custom">
            <span class="fas fa-house-user fa-2x text-primary"></span>
            <div class="ms-4 mt-2">
              <h5>Remote Working Facilities</h5>
              <p class="mb-0">
                People are more productive working at home than people would have expected. Some people thought that everything was just going to fall apart, and it hasn’t.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div
            class="single-feature d-flex mt-4 mt-xl-0 mt-lg-0 mt-md-0 p-5 bg-white rounded-custom"
          >
            <span class="fas fa-clock fa-2x text-primary"></span>
            <div class="ms-4 mt-2">
              <h5>Flexible Working Hours</h5>
              <p class="mb-0">
                People today really value workplace flexibility and remote work because it allows them to focus their energies on work and life as opposed to commuting or other complications due to geography.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="single-feature d-flex mt-4 p-5 bg-white rounded-custom">
            <span class="fas fa-user-friends fa-2x text-primary"></span>
            <div class="ms-4 mt-2">
              <h5>Friendly Skilled Team</h5>
              <p class="mb-0">
                Individual commitment to a group effort–that is what makes a teamwork, a company work, a society work, a civilization work.
              </p>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-6 col-lg-6">
          <div class="single-feature d-flex mt-4 p-5 bg-white rounded-custom">
            <span class="fas fa-briefcase-medical fa-2x text-primary"></span>
            <div class="ms-4 mt-2">
              <h5>Medical Insurance Facilities</h5>
              <p class="mb-0">
                Credibly syndicate enterprise total linkage whereas cost
                effective innovate state of the art data without
                multifunctional.
              </p>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
