<template>
  <section class="promo-section ptb-120">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-10">
          <div class="section-heading text-center">
            <h2>We Help you Stay Connected</h2>
            <p>
              Developing Enterprise applications that deliver business value.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div
            class="promo-single position-relative text-center bg-white custom-shadow rounded-custom p-5 mb-4"
          >
            <div class="promo-icon mb-32">
              <i class="fas fa-wifi text-primary fa-3x"></i>
            </div>
            <div class="promo-info">
              <h3 class="h5">Stay Connected</h3>
              <p class="mb-0">
                We handle mission-critical projects on time. 24x7 Support for maintance projects.
              </p>
            </div>
            <!--pattern start-->
            <div class="dot-shape-bg position-absolute z--1 left--40 top--40">
              <img src="@/assets/img/shape/dot-big-square.svg" alt="shape" />
            </div>
            <!--pattern end-->
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div
            class="promo-single position-relative text-center bg-white custom-shadow rounded-custom p-5 mb-4"
          >
            <div class="promo-icon mb-32">
              <i class="fas fa-user-friends text-success fa-3x"></i>
            </div>
            <div class="promo-info">
              <h3 class="h5">Expert professionals</h3>
              <p class="mb-0">
                We are a team of expert professionals working towards your success.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div
            class="promo-single position-relative text-center bg-white custom-shadow rounded-custom p-5 mb-4"
          >
            <div class="promo-icon mb-32">
              <i class="fas fa-bezier-curve text-danger fa-3x"></i>
            </div>
            <div class="promo-info">
              <h3 class="h5">Software Services</h3>
              <p class="mb-0">
                Developing software apps and platforms based on modern technology stacks.
              </p>
            </div>
            <!--pattern start-->
            <div
              class="dot-shape-bg position-absolute z--1 right--40 bottom--40"
            >
              <img src="@/assets/img/shape/dot-big-square.svg" alt="shape" />
            </div>
            <!--pattern end-->
          </div>
        </div>
      </div>
      <div class="customer-section pt-60">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-11 col-12">
              <div class="customer-logos-grid text-center">
                <img
                  src="@/assets/img/clients/employer.png"
                  width="150"
                  alt="clients logo"
                  class="img-fluid p-1 px-md-2 p-lg-3 m-auto"
                />
                <img
                  src="@/assets/img/clients/locum.png"
                  width="150"
                  alt="clients logo"
                  class="img-fluid p-1 px-md-2 p-lg-3 m-auto"
                />
                <img
                  src="@/assets/img/clients/doctor-lite.png"
                  width="150"
                  alt="clients logo"
                  class="img-fluid p-1 px-md-2 p-lg-3 m-auto"
                />
                <img
                  src="@/assets/img/clients/smart-locum.png"
                  width="150"
                  alt="clients logo"
                  class="img-fluid p-1 px-md-2 p-lg-3 m-auto"
                />
                
              </div>
              <p class="text-center mt-5 mb-0 h6">
                Trusted by huge number of Companies Around the World
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PromoThree',
};
</script>
