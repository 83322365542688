<template>
  <div
    class="offcanvas offcanvas-end d-xl-none"
    tabindex="-1"
    id="offcanvasWithBackdrop"
  >
    <div class="offcanvas-header d-flex align-items-center mt-4">
      <router-link
        to="/"
        class="d-flex align-items-center mb-md-0 text-decoration-none"
      >
        <img
          src="@/assets/img/logo-color.png"
          alt="logo"
          class="img-fluid ps-2"
        />
      </router-link>
      <button
        type="button"
        class="close-btn text-danger"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      >
        <i class="far fa-close"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <ul class="nav col-12 col-md-auto justify-content-center main-menu">
        <li>
          <router-link to="/" class="nav-link">Home</router-link>
        </li>
        <li>
          <router-link to="/services" class="nav-link">Services</router-link>
        </li>
        <li>
          <router-link to="/about-us" class="nav-link">About</router-link>
        </li>
        <li>
          <router-link to="/contact" class="nav-link">Contact Us</router-link>
        </li>
        <li>
          <router-link to="/career" class="nav-link">Career</router-link>
        </li>
      </ul>
      <div class="action-btns mt-4 ps-3">
        <router-link to="/request-for-demo" class="btn btn-primary"
          >Request For Demo</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Offcanvas",
};
</script>
