<template>
  <div>
    <Navbar />
    <BreadCrumb
      title="Contact Us"
      desc="Let's make something awesone together..."
    />
    <ContactPromo />
    <ContactFormTwo />
    <FooterWithBg />
  </div>
</template>

<script>
import BreadCrumb from "../../components/common/BreadCrumb.vue";
import ContactFormTwo from "../../components/contacts/ContactFormTwo.vue";
import FooterWithBg from "../../components/footer/FooterWithBg.vue";
import Navbar from "../../components/nav/Navbar.vue";
import ContactPromo from "../../components/promo/ContactPromo.vue";
export default {
  name: "Contact",
  components: {
    BreadCrumb,
    Navbar,
    ContactPromo,
    FooterWithBg,
    ContactFormTwo,
  },
};
</script>
