<template>
  <section class="job-details ptb-120">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-lg-8 pe-lg-5">
          <div class="job-details-wrap">
            <h2>Job Details</h2>
            <p>
              We are looking for a Junior Front-end React, Angular Developer who has a personal drive to get things finished with a quality focus, seeks, gives, and constructively receives feedback, including but not limited to code and design reviews.
            </p>
            <div class="job-details-info mt-5">
              <h3 class="h5">Job Responsibilities</h3>
              <ul class="content-list list-unstyled">
                <li>
                  Build modern, interactive websites using Angular / React / Vue
                </li>
                <li>
                  Feature development
                </li>
                <li>
                  Communication with a client and other team layers
                </li>
                <li>
                  Bug fixing
                </li>
                <li>
                  Stay current on the latest technologies
                </li>
                <li>
                  Project support
                </li>
                <li>
                  Deliver high quality, tested and secure code using best practices
                </li>
              </ul>
            </div>
            <div class="job-details-info mt-5">
              <h3 class="h5">Job Requirements</h3>
              <ul class="content-list list-unstyled">
                <li>
                  1+ year of experience as Frontend Developer
                </li>
                <li>
                  Experience with Angular / React framework, preferably latest versions
                </li>
                <li>
                  Experience with HTML and CSS
                </li>
                <li>
                  Experience with TypeScript and JavaScript
                </li>
                <li>
                  Familiarity with RESTful APIs
                </li>
                <li>
                  EWorking knowhow of Git
                </li>
                <li>
                  An active interest in staying up to date with new technology, new frameworks, new languages and other developments
                </li>
                <li>
                  Fluent in English speaking, reading, and writing.
                </li>
              </ul>
            </div>

            <img
              src="@/assets/img/apply-now-1.jpg"
              class="img-fluid mt-5 rounded-custom"
              alt="apply"
            />
          </div>
        </div>
        <div class="col-lg-4">
          <div
            class="job-overview-wrap bg-light p-5 sticky-sidebar rounded-custom mt-5 mt-lg-0"
          >
            <h5>Job Overviews</h5>
            <ul class="job-overview-list list-unstyled mt-4">
              <li>
                <i class="far fa-map-marker-alt text-primary"></i>
                <div class="overview-item">
                  <h6 class="mb-0">Location:</h6>
                  <span>Hyderabad, India</span>
                </div>
              </li>
              <li>
                <i class="far fa-user text-primary"></i>
                <div class="overview-item">
                  <h6 class="mb-0">Job Title:</h6>
                  <span>Jr FE Developer</span>
                </div>
              </li>
              <li>
                <i class="far fa-clock text-primary"></i>
                <div class="overview-item">
                  <h6 class="mb-0">Hours:</h6>
                  <span>48h / week</span>
                </div>
              </li>
            </ul>
            <router-link to="/contact" class="btn btn-primary d-block mt-5"
              >Apply now</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
