<template>
  <section
    class="feature-section ptb-120"
    :class="[darkBg ? 'bg-dark' : '', bgLight ? 'bg-light' : '']"
  >
    <div class="container">
      <div
        class="row justify-content-center"
        :class="hasTitle ? 'row justify-content-center' : 'd-none'"
      >
        <div class="col-lg-6 col-md-10">
          <div class="section-heading text-center" data-aos="fade-up">
            <h2>Your time has come to rule the digital realm.</h2>
            <p>
              We offer a full range of app building servicesto give your business wings. Please look at our top offerings below.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="feature-grid">
            <div
              class="feature-card shadow-sm rounded-custom p-5"
              :class="
                darkBg
                  ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                  : 'bg-white'
              "
              data-aos="fade-up"
              data-aos-delay="50"
            >
              <div
                class="icon-box d-inline-block rounded-circle bg-primary-soft mb-32"
              >
                <i class="fal fa-analytics icon-sm text-primary"></i>
              </div>
              <div class="feature-content">
                <h3 class="h5">Web Development</h3>
                <p>
                  Get a fast, optimized, and responsive website with Tech Cloud to stand out from the crowd! Our crew of expert designers and developers can build an incredibly high-quality website that will bring nothing but success to your business.
                </p>
              </div>
              <router-link
                to="/services"
                class="link-with-icon text-decoration-none mt-3"
                >View Details <i class="far fa-arrow-right"></i
              ></router-link>
            </div>
            <div
              class="feature-card shadow-sm rounded-custom p-5"
              :class="
                darkBg
                  ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                  : 'bg-white'
              "
              data-aos="fade-up"
              data-aos-delay="50"
            >
              <div
                class="icon-box d-inline-block rounded-circle bg-success-soft mb-32"
              >
                <i class="fal fa-file-chart-line icon-sm text-success"></i>
              </div>
              <div class="feature-content">
                <h3 class="h5">Mobile App Development</h3>
                <p class="mb-0">
                  Stop relying on dated strategies to connect with prospects and step forward into growth with a mobile app. We use advanced tools and methods to create engaging applications that take profits to the next level.
                </p>
              </div>
              <router-link
                to="/services"
                class="link-with-icon text-decoration-none mt-3"
                >View Details <i class="far fa-arrow-right"></i
              ></router-link>
            </div>
            <div
              class="feature-card shadow-sm rounded-custom p-5"
              :class="
                darkBg
                  ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                  : 'bg-white'
              "
              data-aos="fade-up"
              data-aos-delay="50"
            >
              <div
                class="icon-box d-inline-block rounded-circle bg-danger-soft mb-32"
              >
                <i class="fal fa-user-friends icon-sm text-danger"></i>
              </div>
              <div class="feature-content">
                <h3 class="h5">Full Stack Development</h3>
                <p class="mb-0">
                  Give your application the support it needs to outperform the competition! Our full-stack developers understand modern technologies and implement long-term solutions for optimal functionality of your website or mobile app.
                </p>
              </div>
              <router-link
                to="/services"
                class="link-with-icon text-decoration-none mt-3"
                >View Details <i class="far fa-arrow-right"></i
              ></router-link>
            </div>
            <div
              class="feature-card shadow-sm rounded-custom p-5"
              :class="
                darkBg
                  ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                  : 'bg-white'
              "
              data-aos="fade-up"
              data-aos-delay="50"
            >
              <div
                class="icon-box d-inline-block rounded-circle bg-dark-soft mb-32"
              >
                <i class="fal fa-spell-check icon-sm text-dark"></i>
              </div>
              <div class="feature-content">
                <h3 class="h5">Deployment & Server Maintenance</h3>
                <p class="mb-0">
                  Get your new systems deployed properly and say goodbye to workflow interruptions with Tech Cloud! We have complete command over deployment, server maintenance tasks, and offer comprehensive solutions tailored to your unique needs.
                </p>
              </div>
              <router-link
                to="/services"
                class="link-with-icon text-decoration-none mt-3"
                >View Details <i class="far fa-arrow-right"></i
              ></router-link>
            </div>
            <div
              class="feature-card shadow-sm rounded-custom p-5"
              :class="
                darkBg
                  ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                  : 'bg-white'
              "
              data-aos="fade-up"
              data-aos-delay="50"
            >
              <div
                class="icon-box d-inline-block rounded-circle bg-warning-soft mb-32"
              >
                <i class="fal fa-cog icon-sm text-warning"></i>
              </div>
              <div class="feature-content">
                <h3 class="h5">Hire a Developer</h3>
                <p class="mb-0">
                  Want to hire a seasoned developer for your website/mobile application building project? Look no further! We can connect you with a skilled developer who will take the burden off your shoulders by fulfilling a wide range of responsibilities.
                </p>
              </div>
              <router-link
                to="/services"
                class="link-with-icon text-decoration-none mt-3"
                >View Details <i class="far fa-arrow-right"></i
              ></router-link>
            </div>

            <div
              class="feature-card shadow-sm rounded-custom p-5"
              :class="
                darkBg
                  ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                  : 'bg-white'
              "
              data-aos="fade-up"
              data-aos-delay="50"
            >
              <div
                class="icon-box d-inline-block rounded-circle bg-warning-soft mb-32"
              >
                <i class="fal fa-cog icon-sm text-warning"></i>
              </div>
              <div class="feature-content">
                <h3 class="h5">CMS Development</h3>
                <p class="mb-0">
                  Control, manage, and maintain your website effortlessly with our CMS software solutions. We build feature-packed, easy-to-use content management systems affordably to streamline your web operations regardless of the size.
                </p>
              </div>
              <router-link
                to="/services"
                class="link-with-icon text-decoration-none mt-3"
                >View Details <i class="far fa-arrow-right"></i
              ></router-link>
            </div>


            <div
              class="feature-card shadow-sm rounded-custom p-5"
              :class="
                darkBg
                  ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                  : 'bg-white'
              "
              data-aos="fade-up"
              data-aos-delay="50"
            >
              <div
                class="icon-box d-inline-block rounded-circle bg-warning-soft mb-32"
              >
                <i class="fal fa-cog icon-sm text-warning"></i>
              </div>
              <div class="feature-content">
                <h3 class="h5">Technical Support</h3>
                <p class="mb-0">
                  Keep your IT infrastructure in optimal shape and boost your crew’s productivity! We provide ongoing technical support to prevent/resolve technical issues so your business can gain a competitive advantage and thrive long term.
                </p>
              </div>
              <router-link
                to="/services"
                class="link-with-icon text-decoration-none mt-3"
                >View Details <i class="far fa-arrow-right"></i
              ></router-link>
            </div>

            <div
              class="feature-card shadow-sm rounded-custom p-5"
              :class="
                darkBg
                  ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                  : 'bg-white'
              "
              data-aos="fade-up"
              data-aos-delay="50"
            >
              <div
                class="icon-box d-inline-block rounded-circle bg-warning-soft mb-32"
              >
                <i class="fal fa-cog icon-sm text-warning"></i>
              </div>
              <div class="feature-content">
                <h3 class="h5">UI/UX Design</h3>
                <p class="mb-0">
                  Maximize visibility, consistency, and performance of your app with Tech Cloud! We are passionate about building aesthetically pleasing and high-performing applications that improve brand awareness, engage users, and drive them to the sales funnel.
                </p>
              </div>
              <router-link
                to="/services"
                class="link-with-icon text-decoration-none mt-3"
                >View Details <i class="far fa-arrow-right"></i
              ></router-link>
            </div>



          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FeatureGrid",
  props: {
    hasTitle: String,
    darkBg: String,
    bgLight: String,
  },
};
</script>
