<template>
  <section class="contact-us-form pt-60 pb-120">
    <div class="container">
      <div class="row justify-content-lg-between align-items-center">
        <div class="col-lg-6 col-md-8">
          <div class="section-heading">
            <h2>Get In Touch With Us</h2>
            <p>
              Connect with CloudTech today and discover how we can deliver results.
            </p>
          </div>

          <div v-if="success" class="alert alert-success alert-dismissible">
            <button type="button" @click="closeSuccess" class="btn-close" data-bs-dismiss="alert"></button>
            Thank you for contacting us!, we will get back to you shortly.
          </div>

          <div v-if="error" class="alert alert-danger alert-dismissible">
            <button type="button" @click="closeError" class="btn-close" data-bs-dismiss="alert"></button>
            Sorry, Something went wrong please try after sometime.
          </div>

          <form ref="form" @submit.prevent="submitForm" class="register-form">
            <div class="row">
              <div class="col-sm-6">
                <label for="firstName" class="mb-1"
                  >First name <span class="text-danger">*</span></label
                >
                <div class="input-group mb-3">
                  <input
                    type="text"
                    id="firstName"
                    v-model="first_name"
                    placeholder="First name"
                    aria-label="First name"
                    name="first_name"
                    :class="{'form-control':true,['is-invalid']:errors['first_name'] == true}"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <label for="lastName" class="mb-1">Last name<span class="text-danger">*</span></label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    :class="{'form-control':true,['is-invalid']:errors['last_name'] == true}"
                    id="lastName"
                    placeholder="Last name"
                    aria-label="Last name"
                    v-model="last_name"
                    name="last_name"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <label for="phone" class="mb-1"
                  >Phone <span class="text-danger">*</span></label
                >
                <div class="input-group mb-3">
                  <input
                    type="text"
                    :class="{'form-control':true,['is-invalid']:errors['phone'] == true}"
                    id="phone"
                    placeholder="Phone"
                    aria-label="Phone"
                    v-model="phone"
                    name="phone"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <label for="email" class="mb-1"
                  >Email<span class="text-danger">*</span></label
                >
                <div class="input-group mb-3">
                  <input
                    type="text"
                    :class="{'form-control':true,['is-invalid']:errors['email'] == true}"
                    id="email"
                    placeholder="Email"
                    aria-label="Email"
                    v-model="email"
                    name="email"
                  />
                </div>
              </div>
              <div class="col-12">
                <label for="yourMessage" class="mb-1"
                  >Message <span class="text-danger">*</span></label
                >
                <div class="input-group mb-3">
                  <textarea
                    :class="{'form-control':true,['is-invalid']:errors['message'] == true}"
                    id="yourMessage"
                    placeholder="How can we help you?"
                    style="height: 120px"
                    v-model="message"
                    name="message"
                  ></textarea>
                </div>
              </div>
            </div>
            <button type="submit" v-if="!loading" class="btn btn-primary mt-4">
              Get in Touch
            </button>
            
            <button type="submit" v-if="loading" class="btn btn-primary  mt-4" disabled>
              <span class="spinner-border spinner-border-sm"></span>
              Loading..
            </button>


          </form>
        </div>
        <div class="col-lg-5 col-md-10">
          <div class="contact-us-img">
            <img
              src="@/assets/img/contact-us-img-2.svg"
              alt="contact us"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import emailjs from '@emailjs/browser';
export default {
  name: 'ContactFormTwo',
  data () {
    return {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      message: '',
      errors: {},
      submitted: false,
      success: false,
      error: false,
      loading: false
    }
  },
  watch: {
    first_name: function() {
        if(this.submitted) {
          if(this.first_name.trim() != '') {
            this.errors['first_name'] = false;
          } else {
            this.errors['first_name'] = true;
          }
        }
    },
    last_name: function() {
        if(this.submitted) {
          if(this.last_name.trim() != '') {
            this.errors['last_name'] = false;
          } else {
            this.errors['last_name'] = true;
          }
        }
    },
    email: function() {
        if(this.submitted) {
          if(this.email.trim() !== '' && (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email.trim())) ) {
            this.errors['email'] = false;
          } else {
            this.errors['email'] = true;
          }
        }
    },
    phone: function() {
        if(this.submitted) {
          if(this.phone.trim() != '') {
            this.errors['phone'] = false;
          } else {
            this.errors['phone'] = true;
          }
        }
    },
    message: function() {
        if(this.submitted) {
          if(this.message.trim() != '') {
            this.errors['message'] = false;
          } else {
            this.errors['message'] = true;
          }
        }
    },
  },
  methods:{
    closeSuccess: function() {
      this.success = false 
    },
    closeError: function() {
      this.error = false 
    },
    submitForm: function(e) {
      if(this.loading) {
        return false;
      }
      let newErrors = {};
      let hasError = true;
      if(this.first_name.trim() !== '') {
        newErrors['first_name'] = false;
      } else {
        hasError = false;
        newErrors['first_name'] = true;
      }

      if(this.last_name.trim() !== '') {
      newErrors['last_name'] = false;
      } else {
        hasError = false;
        newErrors['last_name'] = true;
      }

      if(this.email.trim() !== '' && (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email.trim())) ) {
        newErrors['email'] = false;
      } else {
        hasError = false;
        newErrors['email'] = true;
      }

      if(this.phone.trim() !== '') {
        newErrors['phone'] = false;
      } else {
        hasError = false;
        newErrors['phone'] = true;
      }

      if(this.phone.trim() !== '') {
        newErrors['message'] = false;
      } else {
        hasError = false;
        newErrors['message'] = true;
      }
      this.errors = newErrors;
      
      if(hasError) {
        this.loading = true;
        this.submitted = false;
        emailjs.sendForm('service_3tbo2rg', 'template_xduuvq8', this.$refs.form, 'CLdo-stjYyonrrzlc')
        .then((result) => {
          this.loading = false;
          this.first_name ='';
          this.last_name ='';
          this.email ='';
          this.phone ='';
          this.message ='';
          this.success = true;
          this.error = false;
        }, () => {
          this.loading = false
          this.success = false;
          this.error = true;
        });
      } else {
        this.submitted = true;
      }
    }
  }
};
</script>

<style scoped>
.contact-us-form {
  background-image: url("../../assets/img/shape/contact-us-bg.svg");
  background-repeat: no-repeat;
  background-position: bottom left;
}
.form-control.is-invalid {
  border-color: #dc3545 !important;
}
</style>
