<template>
  <section class="our-story-section pt-60 pb-120">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-lg-5 col-md-12 order-lg-1">
          <div class="section-heading sticky-sidebar">
            <h4 class="h5 text-primary">Our Story</h4>
            <h2>Boosting your business performance has never been easier!</h2>
            <p>
              Tech Cloud is a website and mobile app development company in location. Our crew consists of highly skilled experts with a combined experience of 10+ years in building fully functional, attractive, responsive, user-friendly, and feature-rich applications that help businesses soar. Whether you need an e-commerce website, a business website, or a super smooth mobile application, our creative, flexible, and affordable solutions are the only thing you need to translate your concept into a million-dollar reality.
            </p>
            <p>
            Don’t let an inexperienced company waste your time and resources. Hire us and ignite your business with a meticulously built application.
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 order-lg-0">
          <div class="story-grid-wrapper position-relative">
            <!--animated shape start-->
            <ul
              class="position-absolute animate-element parallax-element shape-service"
            >
              <li class="layer" data-depth="0.02">
                <img
                  src="@/assets/img/color-shape/image-2.svg"
                  alt="shape"
                  class="img-fluid position-absolute color-shape-2 z-5"
                />
              </li>
              <li class="layer" data-depth="0.03">
                <img
                  src="@/assets/img/color-shape/feature-3.svg"
                  alt="shape"
                  class="img-fluid position-absolute color-shape-3"
                />
              </li>
            </ul>
            <!--animated shape end-->
            <div
              class="story-grid rounded-custom bg-dark overflow-hidden position-relative"
            >
              <div class="story-item bg-light border">
                <h3 class="display-5 fw-bold mb-1 text-success">50+</h3>
                <h6 class="mb-0">Active Users</h6>
              </div>
              <div class="story-item bg-white border">
                <h3 class="display-5 fw-bold mb-1 text-primary">10+</h3>
                <h6 class="mb-0">Team Members</h6>
              </div>
              <div class="story-item bg-white border">
                <h3 class="display-5 fw-bold mb-1 text-dark">$1M+</h3>
                <h6 class="mb-0">Revenue Per/Year</h6>
              </div>
              <div class="story-item bg-light border">
                <h3 class="display-5 fw-bold mb-1 text-warning">8 Years</h3>
                <h6 class="mb-0">In Business</h6>
              </div>
              <div class="story-item bg-light border">
                <h3 class="display-5 fw-bold mb-1 text-danger">25+</h3>
                <h6 class="mb-0">Clients Worldwide</h6>
              </div>
              <div class="story-item bg-white border">
                <h3 class="display-5 fw-bold mb-1 text-primary">10+</h3>
                <h6 class="mb-0">Projects Completed</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
