<template>
  <section class="feature-section-two ptb-120 bg-light">
    <div class="container">
      <div
        class="row align-items-center justify-content-lg-between justify-content-center"
      >
        <div class="col-lg-6 col-md-12">
          <div class="feature-content-wrap">
            <div class="section-heading">
              <h2>We are development experts on all technologies</h2>
              <p>
                Our end-to-end software development offering robust effective IT solutions to our valuable clients.
              </p>
            </div>
            <ul class="list-unstyled mb-0">
              <li class="d-flex align-items-start mb-4">
                <div class="icon-box bg-primary rounded me-4">
                  <i class="fas fa-bezier-curve text-white"></i>
                </div>
                <div class="icon-content">
                  <h3 class="h5">Software Development</h3>
                  <p>
                    We offer attractive powerful Web development Services using latest technology stalk like React, Angular, Vue, Node, PHP and Python.
                  </p>
                </div>
              </li>
              <li class="d-flex align-items-start mb-4">
                <div class="icon-box bg-danger rounded me-4">
                  <i class="fas fa-fingerprint text-white"></i>
                </div>
                <div class="icon-content">
                  <h3 class="h5">Web Designing & Development</h3>
                  <p>
                    We offer attractive powerful Web development Services that fits wide range of mobile devices in addition to that of desktops, Laptops and Tablets.
                  </p>
                </div>
              </li>
              <li class="d-flex align-items-start mb-4 mb-lg-0">
                <div class="icon-box bg-dark rounded me-4">
                  <i class="fas fa-headset text-white"></i>
                </div>
                <div class="icon-content">
                  <h3 class="h5">Deployments and Maintenance</h3>
                  <p>
                    We offer deployments for your projects using AWS, Azure and google cloud platforms and will provide 24X7 support for server maintenance.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 col-md-7">
          <div class="feature-img-wrap">
            <img
              src="@/assets/img/feature-hero-img.svg"
              alt="feature image"
              class="img-fluid rounded-custom"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeatureFive',
};
</script>
