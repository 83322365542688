<template>
  <section class="job-details ptb-120">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-lg-8 pe-lg-5">
          <div class="job-details-wrap">
            <h2>Job Details</h2>
            <p>
              We are looking for a dynamic UI/UX designer who will be responsible for the user experience (UX) and user interface (UI) design of our prod. You will ensure that all elements of the online user experience are optimized for improved usability, usefulness, and exceptional visual design.
            </p>
            <div class="job-details-info mt-5">
              <h3 class="h5">Job Responsibilities</h3>
              <ul class="content-list list-unstyled">
                <li>
                  Evaluate and set the best UI practices and standards
                </li>
                <li>
                  Understand the domain and technology of the product area in which you work on
                </li>
                <li>
                  Collaborate with the developers and break down the designs to release cycle
                </li>
                <li>
                  Design across multiple form factors including web, iOS and Android
                </li>
              </ul>
            </div>
            <div class="job-details-info mt-5">
              <h3 class="h5">Job Requirements</h3>
              <ul class="content-list list-unstyled">
                <li>
                  Minimum 2 years of experience as UI/UX designer for Mobile & Web applications
                </li>
                <li>
                  Knowledge of wireframe tools
                </li>
                <li>
                  Strong skills in using Sketch or Figma or Adobe XD
                </li>
                <li>
                  Up-to-date knowledge of design software
                </li>
                <li>
                  Team spirit and have strong communication skills to collaborate with various stakeholders
                </li>
              </ul>
            </div>

            <img
              src="@/assets/img/apply-now-1.jpg"
              class="img-fluid mt-5 rounded-custom"
              alt="apply"
            />
          </div>
        </div>
        <div class="col-lg-4">
          <div
            class="job-overview-wrap bg-light p-5 sticky-sidebar rounded-custom mt-5 mt-lg-0"
          >
            <h5>Job Overviews</h5>
            <ul class="job-overview-list list-unstyled mt-4">
              <li>
                <i class="far fa-map-marker-alt text-primary"></i>
                <div class="overview-item">
                  <h6 class="mb-0">Location:</h6>
                  <span>Hyderabad, India</span>
                </div>
              </li>
              <li>
                <i class="far fa-user text-primary"></i>
                <div class="overview-item">
                  <h6 class="mb-0">Job Title:</h6>
                  <span>UI/UX Designer</span>
                </div>
              </li>
              <li>
                <i class="far fa-clock text-primary"></i>
                <div class="overview-item">
                  <h6 class="mb-0">Hours:</h6>
                  <span>48h / week</span>
                </div>
              </li>
            </ul>
            <router-link to="/contact" class="btn btn-primary d-block mt-5"
              >Apply now</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
