<template>
  <section class="sign-up-in-section bg-dark ptb-120 page-header">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-xl-5 col-lg-5 col-md-12 order-1 order-lg-0">
          <div class="testimonial-tab-slider-wrap mt-5">
            <h1 class="fw-bold text-white display-5">
              Set up your business for success with us.
            </h1>
            <p>
              No matter the type and size of your concept, we can comfortably make it a reality. Please fill out the form now to access our powerful but cheap solutions.
            </p>
            <hr />
            <div
              class="tab-content testimonial-tab-content text-white-80"
              id="pills-tabContent"
            >
              <div
                class="tab-pane fade show active"
                id="testimonial-tab-1"
                role="tabpanel"
              >
                <blockquote class="blockquote">
                  <em
                    >"With Tech Cloud Sol, you’ll develop high-performing solutions and overcome business challenges before they even appear. Work with us and build a lasting partnership that transforms your bottom line."</em
                  >
                </blockquote>
                
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-lg-7 col-md-12 order-0 order-lg-1">
          <ContForm />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ContForm from "../contacts/ContForm.vue";
export default {
  components: { ContForm },
};
</script>
