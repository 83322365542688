<template>
  <div>
    <Navbar />
    <BreadCrumb
      title="We are Development Experts"
      desc="Give your brand recognition with a stunning application."
    />
    <FeatureGrid hasTitle="hasTitle" bgLight="bgLight" />
    <TestimonialSlider />
    <FooterWithBg />
  </div>
</template>

<script>
import BreadCrumb from "../../components/common/BreadCrumb.vue";
import FeatureGrid from "../../components/features/FeatureGrid.vue";
import Navbar from "../../components/nav/Navbar.vue";
import TestimonialSlider from "../../components/testimonial/TestimonialSlider.vue";
import FooterWithBg from "../../components/footer/FooterWithBg.vue";
export default {
  components: {
    Navbar,
    BreadCrumb,
    TestimonialSlider,
    FeatureGrid,
    FooterWithBg,
  },
};
</script>

<style></style>
