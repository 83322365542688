import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/pages/HomeFive.vue";
import Services from "../views/pages/Services.vue";
import SingleService from "../views/pages/ServiceSingle.vue";
import Contact from "../views/pages/Contact.vue";
import AboutUs from "../views/pages/AboutUs.vue";
import Career from "../views/pages/Career.vue";
import CareerSingle from "../views/pages/CareerSingle.vue";
import FeDeveloper from "../views/pages/FeDeveloper.vue";
import UiDesigner from "../views/pages/UiDesigner.vue";
import FullstackDeveloper from "../views/pages/FullstackDeveloper.vue";
import BeDeveloper from "../views/pages/BeDeveloper.vue";
import RequestForDemo from "../views/pages/RequestForDemo.vue";
import ComingSoon from "../views/pages/ComingSoon.vue";
import NotFound from "../views/pages/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
    name: "Home",
    meta: {
      title: "TechCloud Solutions",
    },
  },
  {
    path: "/services",
    name: "Services",
    component: Services,
    meta: {
      title: "TechCloud Solutions - Services",
    },
  },
  {
    path: "/career-single",
    name: "CareerSingle",
    component: CareerSingle,
    meta: {
      title: "Career Single - TechCloud Solutions - Software & IT Solutions",
    },
  },
  {
    path: "/fe-developer",
    name: "FeDeveloper",
    component: FeDeveloper,
    meta: {
      title: " TechCloud Solutions Fe Developer",
    },
  },
  {
    path: "/be-developer",
    name: "BeDeveloper",
    component: BeDeveloper,
    meta: {
      title: " TechCloud Solutions Banck End Developer",
    },
  },
  {
    path: "/ui-designer",
    name: "UiDesigner",
    component: UiDesigner,
    meta: {
      title: " TechCloud Solutions UI/UX Designer",
    },
  },
  {
    path: "/fullstack-developer",
    name: "FullstackDeveloper",
    component: FullstackDeveloper,
    meta: {
      title: " TechCloud Solutions Fullstack Developer",
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: {
      title: "TechCloud Solutions - Contact",
    },
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: AboutUs,
    meta: {
      title: "TechCloud Solutions - About Us",
    },
  },
  {
    path: "/request-for-demo",
    name: "RequestForDemo",
    component: RequestForDemo,
    meta: {
      title: "Request For Demo - TechCloud Solutions - Software & IT Solutions",
    },
  },
  {
    path: "/career",
    name: "Career",
    component: Career,
    meta: {
      title: "Career - TechCloud Solutions- Software & IT Solutions",
    },
  },
  {
    path: "/career-single",
    name: "CareerSingle",
    component: CareerSingle,
    meta: {
      title: "Career Single - TechCloud Solutions - Software & IT Solutions",
    },
  },
  {
    path: "/coming-soon",
    name: "ComingSoon",
    component: ComingSoon,
    meta: {
      title: "Coming Soon - TechCloud Solutions - Software & IT Solutions",
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
    meta: {
      title: "TechCloud Solutions - Not Found",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + " – Software & IT Solutions";
  }
});

export default router;
