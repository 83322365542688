<template>
  <section class="why-choose-us pt-60 pb-120 bg-dark">
    <div class="container">
      <div
        class="row justify-content-lg-between justify-content-center align-items-center"
      >
        <div class="col-lg-5 col-md-7 order-1 order-lg-0">
          <div class="why-choose-img position-relative">
            <img
              src="@/assets/img/feature-hero-img-2.svg"
              class="img-fluid"
              alt="duel-phone"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-12 order-0 order-lg-1">
          <div class="why-choose-content">
            <div class="mb-32">
              <h2>We run all kinds of services in form of software</h2>
              <p>
                We will unlock the value of your business by creating powerful Digital Transformation Solutions.
              </p>
            </div>
            <ul class="list-unstyled d-flex flex-wrap list-two-col">
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>Full Stack Development
              </li>
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>Mobile App Development
              </li>
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>Web Application Development
              </li>
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>CMS Development Services
              </li>
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>Technical Support Services
              </li>
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>Deployments and Migrations
              </li>
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>Server maintenance
              </li>
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>UI / UX Services
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ImgContentSix',
};
</script>
