<template>
  <section id="open-positions" class="open-jobs ptb-120">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-lg-4 col-md-12">
          <div class="section-heading">
            <h4 class="h5 text-primary">Our Jobs</h4>
            <h2>Current Available Positions at CloudTech</h2>
          </div>
        </div>
        <div class="col-lg-7 col-md-12">
          <p>
            We're curious, creative, and diverse, driven by the purpose of our work and energized by the joy we get from supporting each other. We build awesome stuff that helps people and love doing it together.
          </p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-12">
          <router-link
            to="/fe-developer"
            class="text-decoration-none mt-4 mt-xl-0 mt-lg-0 single-open-job p-5 bg-dark text-white d-block rounded-custom"
          >
            <div class="d-flex justify-content-between align-items-center">
              <span class="job-time h-6 mb-2"
                ><i class="far fa-briefcase me-2"></i>
                <strong>Remote - Full Time</strong></span
              >
              <span class="badge px-3 py-2 bg-custom-light rounded-pill small"
                >Developer</span
              >
            </div>
            <h3 class="h5">Jr FE Developer</h3>
            <ul class="job-info-list list-inline list-unstyled text-muted">
              <li class="list-inline-item">
                <span class="far fa-house-building me-1"></span> React JS
              </li>
              <li class="list-inline-item">
                <span class="far fa-house-building me-1"></span> Angular
              </li>
              <li class="list-inline-item">
                <span class="far fa-house-building me-1"></span> Vue
              </li>
            </ul>
            <div class="btn btn-outline-light btn-sm d-inline-block mt-4">
              Apply Now
            </div>
          </router-link>
        </div>
        <div class="col-lg-6 col-md-12">
          <router-link
            to="/ui-designer"
            class="text-decoration-none mt-4 mt-xl-0 mt-lg-0 single-open-job p-5 bg-dark text-white d-block rounded-custom"
          >
            <div class="d-flex justify-content-between align-items-center">
              <span class="job-time h-6 mb-2"
                ><i class="far fa-briefcase me-2"></i>
                <strong>Remote - Full Time</strong></span
              >
              <span class="badge px-3 py-2 bg-custom-light rounded-pill small"
                >Designer</span
              >
            </div>
            <h3 class="h5">UI/UX Designer</h3>
            <ul class="job-info-list list-inline list-unstyled text-muted">
              <li class="list-inline-item">
                <span class="far fa-house-building me-1"></span> Figma
              </li>
              <li class="list-inline-item">
                <span class="far fa-house-building me-1"></span> 
Material-UI
              </li>
              <li class="list-inline-item">
                <span class="far fa-house-building me-1"></span> Graphic Design
              </li>
            </ul>
            <div class="btn btn-outline-light btn-sm d-inline-block mt-4">
              Apply Now
            </div>
          </router-link>
        </div>
        <div class="col-lg-6 col-md-12">
          <router-link
            to="/fullstack-developer"
            class="text-decoration-none mt-4 single-open-job p-5 bg-dark text-white d-block rounded-custom"
          >
            <div class="d-flex justify-content-between align-items-center">
              <span class="job-time h-6 mb-2"
                ><i class="far fa-briefcase me-2"></i>
                <strong>Full Time</strong></span
              >
              <span class="badge px-3 py-2 bg-custom-light rounded-pill small"
                >Developer</span
              >
            </div>
            <h3 class="h5">Senior Full Stack Developer</h3>
            <ul class="job-info-list list-inline list-unstyled text-muted">
              <li class="list-inline-item">
                <span class="far fa-house-building me-1"></span> React JS
              </li>
              <li class="list-inline-item">
                <span class="far fa-house-building me-1"></span> Angular
              </li>
              <li class="list-inline-item">
                <span class="far fa-house-building me-1"></span> Node JS
              </li>
              <li class="list-inline-item">
                <span class="far fa-house-building me-1"></span> PHP
              </li>
            </ul>
            <div class="btn btn-outline-light btn-sm d-inline-block mt-4">
              Apply Now
            </div>
          </router-link>
        </div>
        <div class="col-lg-6 col-md-12">
          <router-link
            to="/be-developer"
            class="text-decoration-none mt-4 single-open-job p-5 bg-dark text-white d-block rounded-custom"
          >
            <div class="d-flex justify-content-between align-items-center">
              <span class="job-time h-6 mb-2"
                ><i class="far fa-briefcase me-2"></i>
                <strong>Remote</strong></span
              >
              <span class="badge px-3 py-2 bg-custom-light rounded-pill small"
                >Developer</span
              >
            </div>
            <h3 class="h5">Senior Backend Developer</h3>
            <ul class="job-info-list list-inline list-unstyled text-muted">
              <li class="list-inline-item">
                <span class="far fa-house-building me-1"></span> PHP
              </li>
              <li class="list-inline-item">
                <span class="far fa-house-building me-1"></span> Node
              </li>
              <li class="list-inline-item">
                <span class="far fa-house-building me-1"></span> Python
              </li>
            </ul>
            <div class="btn btn-outline-light btn-sm d-inline-block mt-4">
              Apply Now
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
