<template>
  <section
    class="feature-section-two ptb-120"
    :class="[bgLight ? 'bg-light' : '']"
  >
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-lg-5 col-md-12">
          <div class="section-heading" data-aos="fade-right">
            <h4 class="h5 text-primary">Why Choose Us?</h4>
            <h2>Powerful Solutions for Your Business Needs</h2>
            <p>
              Combining innovation, technology, and experience in one place.
            </p>
            <ul class="list-unstyled mt-5">
              <li class="d-flex align-items-start mb-4">
                <div class="icon-box bg-primary rounded me-4">
                  <i class="fas fa-bezier-curve text-white"></i>
                </div>
                <div class="icon-content">
                  <h3 class="h5">Impressive Portfolio</h3>
                  <p>
                    We have helped countless small businesses become industry giants through ground-breaking web/mobile applications. Please don’t hesitate to check out our work samples to clear any doubts you may have.
                  </p>
                </div>
              </li>
              <li class="d-flex align-items-start mb-4">
                <div class="icon-box bg-danger rounded me-4">
                  <i class="fas fa-bezier-curve text-white"></i>
                </div>
                <div class="icon-content">
                  <h3 class="h5">Affordable Service</h3>
                  <p>
                    We are accessible for all budget ranges. Whether you want an elite website with a plethora of features or a basic mobile app to connect with a broader audience, we can deliver uncompromised quality without troubling your budget.
                  </p>
                </div>
              </li>
              <li class="d-flex align-items-start mb-4">
                <div class="icon-box bg-danger rounded me-4">
                  <i class="fas fa-bezier-curve text-white"></i>
                </div>
                <div class="icon-content">
                  <h3 class="h5">Vast Experience</h3>
                  <p>
                    We have created apps for pretty much every industry over the years. When you hand your project to us, rest assured that we understand your industry, target audience, and will build a solution that truly supports your business.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 col-md-7">
          <div
            class="feature-img-wrap position-relative d-flex flex-column align-items-end"
          >
            <img
              src="@/assets/img/feature-img3.jpg"
              alt="feature image"
              class="img-fluid rounded-custom"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FeatureTwo",
  props: {
    bgLight: String,
  },
};
</script>
