<template>
  <footer class="footer-section">
    <div class="footer-top bg-dark text-white ptb-120 footerBgImg">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-md-8 col-lg-4 mb-md-4 mb-lg-0">
            <div class="footer-single-col">
              <div class="footer-single-col mb-4">
                <img
                  src="@/assets/img/techno-cloud-logo-white.png"
                  alt="logo"
                  class="img-fluid logo-white"
                />
                <img
                  src="@/assets/img/techno-cloud-logo-white.png"
                  alt="logo"
                  class="img-fluid logo-color"
                />
              </div>
              <p>
                Let's make something awesone together...
              </p>

              <div
                  class="action-btns me-5 me-lg-0 d-none d-md-block d-lg-block"
                >
                <router-link to="//meetings.hubspot.com/tech-cloud" target="_blank" class="btn btn-primary"
                    >Request For Meeting</router-link>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-7 mt-4 mt-md-0 mt-lg-0">
            <div class="row">
              <div class="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                <div class="footer-single-col">
                  <h3>Primary Pages</h3>
                  <ul class="list-unstyled footer-nav-list mb-lg-0">
                    <li>
                      <router-link to="/" class="text-decoration-none"
                        >Home</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/about-us" class="text-decoration-none"
                        >About Us</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                <div class="footer-single-col">
                  <h3>Pages</h3>
                  <ul class="list-unstyled footer-nav-list mb-lg-0">
                    <li>
                      <router-link to="/services" class="text-decoration-none"
                        >Services</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/career" class="text-decoration-none"
                        >Career</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                <div class="footer-single-col">
                  <h3>Get In Touch</h3>
                  <ul class="list-unstyled footer-nav-list mb-lg-0">
                    
                    <li>
                      <router-link to="/contact" class="text-decoration-none"
                        >Contact</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/request-for-demo" class="text-decoration-none"
                        >Request For Demo</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterBottom />
  </footer>
</template>

<script>
import FooterBottom from "./FooterBottom.vue";
export default {
  components: { FooterBottom },
};
</script>
