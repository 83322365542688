<template>
  <section
    id="our-team"
    class="team-section ptb-120"
    :class="[bgLight ? 'bg-light' : '']"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 col-md-12">
          <div class="section-heading text-center">
            <h5 class="h6 text-primary">Our Mission</h5>
            <h2>Our Mission</h2>
            <p>
              To revamp existing businesses and get new brands off to a flying start with engaging applications built with cutting-edge technologies. We aim to achieve this goal through dedication, honesty, and transparency in all of our company’s aspects.
            </p>
            <p>
              Obtain our app development services today and transform your business.
            </p>
            <p>
              <div
          class="action-btns me-5 me-lg-0 d-none d-md-block d-lg-block"
        >
          <router-link to="/request-for-demo" class="btn btn-primary"
            >Request For Demo</router-link
          >
        </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
