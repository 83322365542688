<template>
  <div>
    <Navbar />
    <AboutPageHeader />
    <OurStory />
    <FeatureTwo bgLight="bgLight" />
    <OurTeam />    
    <FooterWithBg />
  </div>
</template>

<script>
import AboutPageHeader from "../../components/common/AboutPageHeader.vue";
import FeatureTwo from "../../components/features/FeatureTwo.vue";
import Navbar from "../../components/nav/Navbar.vue";
import OurStory from "../../components/promo/OurStory.vue";
import OurTeam from "../../components/team/OurTeam.vue";
import FooterWithBg from "../../components/footer/FooterWithBg.vue";
export default {
  name: "AboutUs",
  components: {
    AboutPageHeader,
    OurStory,
    FeatureTwo,
    OurTeam,
    Navbar,
    FooterWithBg,    
  },
};
</script>
