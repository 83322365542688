<template>
  <div class="main-wrap">
    <Navbar />
    <CareerPageHeader />
    <JobDetails />
    <RelatedJob />
    <CtaTwo />
    <FooterWithBg />
  </div>
</template>

<script>
import CareerPageHeader from "../../components/career/UiPageHeader.vue";
import JobDetails from "../../components/career/UiJobDetails.vue";
import RelatedJob from "../../components/career/UiRelatedJob.vue";
import FooterWithBg from "../../components/footer/FooterWithBg.vue";
import Navbar from "../../components/nav/Navbar.vue";
export default {
  components: {
    CareerPageHeader,
    Navbar,
    JobDetails,
    FooterWithBg,
    RelatedJob,
  },
};
</script>
