<template>
  <section class="job-details ptb-120">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-lg-8 pe-lg-5">
          <div class="job-details-wrap">
            <h2>Job Details</h2>
            <p>
              TechCloud is looking for a Full Stack Developer who will be highly skilled in computer programming and comfortable with backend development. He will become part of a Continuously transforming environment that influences the experience of millions of users round the world. He should also provide solid technical solutions to complex issues whilst helping deliver the best customer experience. This is a data driven role with a self-starter attitude and has a proven track record to identify unique and creative problem solvers.
            </p>
            <div class="job-details-info mt-5">
              <h3 class="h5">Job Responsibilities</h3>
              <ul class="content-list list-unstyled">
                <li>
                  Leverage best-in-class technologies to drive the conversion of requirements into feature design, its implementation, testing, deployment, and support.
                </li>
                <li>
                  Work as part of a collaborative Agile team working in a SCRUM model surrounded by fun-loving talented engineers.
                </li>
              </ul>
            </div>
            <div class="job-details-info mt-5">
              <h3 class="h5">Job Requirements</h3>
              <ul class="content-list list-unstyled">
                <li>
                  You are a full-stack developer who is skilled in client technologies and passionate about exploring and applying them to help solve real business problems.
                </li>
                <li>
                  2-6 years of software development experience, preferably in a product development company.
                </li>
                <li>
                  Bachelor's degree in Computer Science or similar field.
                </li>
                <li>
                  Deep applicative knowledge of native Javascript, ES6 internal understanding of frameworks like ReactJS, Angular, or any other view libraries.
                </li>
                <li>
                  Exposure to Node JS, PHP or any other server runtimes
                </li>
                <li>
                  Good knowledge of CSS and the ability to put together clean user interfaces using HTML.
                </li>
                <li>
                  Motivated and open to learning new technologies.
                </li>
                <li>
                  Agile/Scrum development methodology knowledge/experience.
                </li>
                <li>
                  Understanding REST, microservices, and Cloud infrastructure is good to have.
                </li>
              </ul>
            </div>

            <div class="job-details-info mt-5">
              <h3 class="h5">What You've Accomplished</h3>
              <ul class="content-list list-unstyled">
                <li>
                  Designed and developed complex user interfaces using component-based frameworks and/or native Javascript, CSS, and HTML.
                </li>
                <li>
                  Have a good understanding of basic software principles, agile scrum practices, and basic development lifecycle.
                </li>
                <li>
                  Have worked end to end on developing a usable product along with writing unit tests and coverage
                </li>
              </ul>
            </div>

            <img
              src="@/assets/img/apply-now-1.jpg"
              class="img-fluid mt-5 rounded-custom"
              alt="apply"
            />
          </div>
        </div>
        <div class="col-lg-4">
          <div
            class="job-overview-wrap bg-light p-5 sticky-sidebar rounded-custom mt-5 mt-lg-0"
          >
            <h5>Job Overviews</h5>
            <ul class="job-overview-list list-unstyled mt-4">
              <li>
                <i class="far fa-map-marker-alt text-primary"></i>
                <div class="overview-item">
                  <h6 class="mb-0">Location:</h6>
                  <span>Hyderabad, India</span>
                </div>
              </li>
              <li>
                <i class="far fa-user text-primary"></i>
                <div class="overview-item">
                  <h6 class="mb-0">Job Title:</h6>
                  <span>Senior Full Stack Developer</span>
                </div>
              </li>
              <li>
                <i class="far fa-clock text-primary"></i>
                <div class="overview-item">
                  <h6 class="mb-0">Hours:</h6>
                  <span>48h / week</span>
                </div>
              </li>
            </ul>
            <router-link to="/contact" class="btn btn-primary d-block mt-5"
              >Apply now</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
