<template>
  <ul class="review-rate mb-0 mt-2 list-unstyled list-inline">
    <li class="list-inline-item">
      <i class="fas fa-star text-warning"></i>
    </li>
    <li class="list-inline-item">
      <i class="fas fa-star text-warning"></i>
    </li>
    <li class="list-inline-item">
      <i class="fas fa-star text-warning"></i>
    </li>
    <li class="list-inline-item">
      <i class="fas fa-star text-warning"></i>
    </li>
    <li class="list-inline-item">
      <i class="fas fa-star text-warning"></i>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
