<template>
  <section
    class="hero-section hero-five ptb-120 min-vh-100 d-flex align-items-center bg-dark text-white position-relative overflow-hidden"
  >
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-lg-6">
          <div class="hero-content-wrap">
            <h5 class="text-warning">Software Technology Company </h5>
            <h1 class="fw-bold display-5">
              Advanced Feature to Grow Your Business
            </h1>
            <p class="lead">
              We develop innovative products and solutions for clients as a technology partner.
            </p>
            <div class="action-btns mt-5">
              <router-link to="/request-for-demo" class="btn btn-primary me-3"
                >Request For Demo</router-link
              >
              <router-link to="/about-us" class="btn btn-outline-light"
                >Learn More</router-link
              >
            </div>
          </div>
          
        </div>
        <div class="col-lg-6 col-md-8 mt-5 mt-lg-0">
          <div class="animated-img-wrap">
            <!--animated shape start-->
            <ul
              class="animate-element parallax-element animated-hero-1"
              style="
                position: relative;
                transform: translate3d(0px, 0px, 0px);
                transform-style: preserve-3d;
                backface-visibility: hidden;
              "
            >
              <li class="layer" data-depth="0.02">
                <img
                  src="@/assets/img/screen/animated-screen-2.svg"
                  alt="shape"
                  class="img-fluid position-absolute type-0"
                />
              </li>
            </ul>
            <!--animated shape end-->
            <img
              src="@/assets/img/screen/animated-screen-1.svg"
              alt="shape"
              class="position-relative img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HeroFive",
};
</script>

<style></style>
