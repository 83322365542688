<template>
  <section
    class="page-header position-relative overflow-hidden ptb-120 bg-dark"
    style="
      background: url('@/assets/img/page-header-bg.svg') no-repeat bottom left;
    "
  >
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-lg-8 col-12">
          <div class="company-info-wrap">
            <div
              class="company-logo p-4 bg-white shadow rounded-custom me-4 mt-2"
            >
              <div class="logo">
                <img
                  src="@/assets/img/company/ui-ux.png"
                  alt="company logo"
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="company-overview">
              <h1 class="display-5 fw-bold">UI/UX Designer</h1>

              
              <ul class="list-unstyled list-inline mb-0 mt-3">
                <li class="list-inline-item">
                <span class="far fa-house-building me-1"></span> Figma
              </li>
              <li class="list-inline-item">
                <span class="far fa-house-building me-1"></span> 
Material-UI
              </li>
              <li class="list-inline-item">
                <span class="far fa-house-building me-1"></span> Graphic Design
              </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        class="bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light right-5"
      ></div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
