<template>
  <header class="main-header w-100" :class="darkNav ? 'position-absolute' : ''">
    <nav
      class="navbar navbar-expand-xl sticky-header"
      :class="[
        darkNav ? 'navbar-dark' : 'navbar-light',
        isSticky ? 'affix' : '',
      ]"
    >
      <div
        class="container d-flex align-items-center justify-content-lg-between position-relative"
      >
        <router-link
          to="/"
          class="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none"
        >
          <img
            src="@/assets/img/techno-cloud-logo-white.png"
            alt="logo"
            class="img-fluid logo-white"
          />
          <img
            src="@/assets/img/techno-cloud-logo.png"
            alt="logo"
            class="img-fluid logo-color"
          />
        </router-link>

        <router-link
          class="navbar-toggler position-absolute right-0 border-0"
          to="#offcanvasWithBackdrop"
          role="button"
        >
          <span
            class="far fa-bars"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasWithBackdrop"
            aria-controls="offcanvasWithBackdrop"
          ></span>
        </router-link>

        <div class="collapse navbar-collapse justify-content-center">
          <ul class="nav col-12 col-md-auto justify-content-center main-menu">
            <li>
              <router-link to="/" class="nav-link"
                >Home</router-link
              >
            </li>
            <li>
              <router-link to="/services" class="nav-link"
                >Services</router-link
              >
            </li>
            <li>
              <router-link to="/about-us" class="nav-link">About</router-link>
            </li>
            <li>
              <router-link to="/contact" class="nav-link">Contact Us</router-link>
            </li>
            <li>
              <router-link to="/career" class="nav-link">Career</router-link>
            </li>
          </ul>
        </div>
        <div
          class="action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block"
        >
          <router-link to="/request-for-demo" class="btn btn-primary"
            >Request For Demo</router-link
          >
        </div>
        <Offcanvas />
      </div>
    </nav>
  </header>
</template>

<script>
import Offcanvas from "./Offcanvas.vue";
export default {
  components: { Offcanvas },
  name: "Navbar",
  props: {
    darkNav: String,
  },
  data() {
    return {
      isSticky: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 80) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    });
  },
};
</script>
