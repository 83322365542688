<template>
  <section class="feature-tab-section ptb-120 bg-light">
    <div class="container">
      <div class="row justify-content-center align-content-center">
        <div class="col-md-10 col-lg-6">
          <div class="section-heading text-center">
            <h4 class="h5 text-primary">Features</h4>
            <h2>Powerful Advanced Features</h2>
            <p>
              Dynamically initiate market positioning total linkage with
              clicks-and-mortar technology progressively procrastinate
              compelling.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <ul
            class="nav justify-content-center feature-tab-list-2 mb-0"
            id="nav-tab"
            role="tablist"
          >
            <li class="nav-item">
              <a
                class="nav-link active"
                href="#tab-1"
                data-bs-toggle="tab"
                data-bs-target="#tab-1"
                role="tab"
                aria-selected="false"
              >
                AI & Data Science
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="#tab-2"
                data-bs-toggle="tab"
                data-bs-target="#tab-2"
                role="tab"
                aria-selected="false"
              >
                Automation Power
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="#tab-3"
                data-bs-toggle="tab"
                data-bs-target="#tab-3"
                role="tab"
                aria-selected="false"
              >
                Advanced Technology
              </a>
            </li>
          </ul>
          <div class="tab-content" id="nav-tabContent">
            <div
              class="tab-pane fade pt-60 active show"
              id="tab-1"
              role="tabpanel"
            >
              <div
                class="row justify-content-center align-items-center justify-content-around"
              >
                <div class="col-lg-5">
                  <div class="feature-tab-info">
                    <h3>AI & Data Science</h3>
                    <p>
                      Continually network effective bandwidth whereas
                      goal-oriented schemas. Intrinsicly incentivize corporate
                      synergy with accurate task bricks-and-clicks leadership
                      skills .
                    </p>
                    <p>
                      Conveniently develop innovative infomediaries for enabled
                      functionalities. Dynamically coordinate leading-edge after
                      virtual potentialities drive multidisciplinary
                      infrastructures.
                    </p>
                    <router-link
                      to="/about-us"
                      class="read-more-link text-decoration-none mt-4 d-block"
                      >Know More About Us <i class="far fa-arrow-right ms-2"></i
                    ></router-link>
                  </div>
                </div>
                <div class="col-lg-5">
                  <img
                    src="@/assets/img/screen/widget-12.png"
                    alt="feature tab"
                    class="img-fluid mt-4 mt-lg-0 mt-xl-0"
                  />
                </div>
              </div>
            </div>
            <div class="tab-pane fade pt-60" id="tab-2" role="tabpanel">
              <div
                class="row justify-content-center align-items-center justify-content-around"
              >
                <div class="col-lg-5">
                  <img
                    src="@/assets/img/screen/widget-8.png"
                    alt="feature tab"
                    class="img-fluid mb-4 mb-lg-0 mb-xl-0"
                  />
                </div>
                <div class="col-lg-5">
                  <div class="feature-tab-info">
                    <h3>Automation Power</h3>
                    <p>
                      Conveniently develop innovative infomediaries for enabled
                      functionalities. Dynamically coordinate leading-edge
                      corporate synergy after virtual potentialities.
                    </p>
                    <p>
                      Continually network effective bandwidth whereas
                      goal-oriented schemas. Intrinsicly with accurate
                      meta-services. Rapidiously parallel task bricks-and-clicks
                      leadership skills with revolutionary.
                    </p>
                    <router-link
                      to="/about-us"
                      class="read-more-link text-decoration-none mt-4 d-block"
                      >Know More About Us <i class="far fa-arrow-right ms-2"></i
                    ></router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade pt-60" id="tab-3" role="tabpanel">
              <div
                class="row justify-content-center align-items-center justify-content-around"
              >
                <div class="col-lg-5">
                  <div class="feature-tab-info">
                    <h3>Advanced Technology</h3>
                    <p>
                      Intrinsicly incentivize corporate synergy with accurate
                      meta-services. Rapidiously parallel task
                      bricks-and-clicks. Leadership skills with revolutionary
                      convergence conveniently develop.
                    </p>
                    <p>
                      Continually expedite business systems without premier
                      testing procedures architect principle-centered e-tailers
                      for progressive maintain open-source solutions.
                    </p>
                    <router-link
                      to="/about-us"
                      class="read-more-link text-decoration-none mt-4 d-block"
                      >Know More About Us <i class="far fa-arrow-right ms-2"></i
                    ></router-link>
                  </div>
                </div>
                <div class="col-lg-5">
                  <img
                    src="@/assets/img/screen/widget-11.png"
                    alt="feature tab"
                    class="img-fluid mt-4 mt-lg-0 mt-xl-0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FeatureTabs",
};
</script>
